<template>
  <div data-t="category-slider" :class="classes">
    <div class="header">
      <NuxtI18nLink :to="link as RouteLocationRaw" class="title-wrapper">
        <StIcon v-if="icon" :svg="icon" class="icon" />
        <StIcon v-if="iconName" :name="iconName" class="icon" />
        <h3 class="title">{{ title }}</h3>
        <StIcon name="chevron-right" size="18" />
      </NuxtI18nLink>
      <StButton class="button" :size="props.size" :to="link" type="gray">
        {{ t('casino.watchAll') }}
        <span class="button-count">{{ gamesTotalCount }}</span>
      </StButton>
    </div>
    <div class="content">
      <StSlider
        v-if="games?.length"
        class="slider"
        :scroll-step="220"
        :items-gap="12"
        :arrow-offset-vertical="18"
      >
        <StSliderItem v-for="game of games" :key="game.gameId">
          <GameCard
            :game="game"
            class="card-game"
            :show-player-count="showPlayerCount"
          />
        </StSliderItem>
        <StSliderItem v-for="item in emptyCardLength" :key="item">
          <div class="card-empty" />
        </StSliderItem>
      </StSlider>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router'
import GameCard from '../GameCard/GameCard.vue'
import type { Game } from '../../types'

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    size?: 's' | 'm'
    showPlayerCount?: boolean
    title: string
    icon?: string | null
    iconName?: string
    link?: RouteLocationRaw
    games?: Game[]
    gamesTotalCount?: number
    fillPlugsTo?: number
  }>(),
  {
    size: 's',
    showPlayerCount: true,
    fillPlugsTo: 6,
  },
)

const classes = computed(() => `size-${props.size}`)

const emptyCardLength = computed(() => {
  if (!props.games || props.games?.length > props.fillPlugsTo) return 0
  return props.fillPlugsTo - props.games.length
})
</script>

<style scoped>
.title-wrapper {
  display: inline-flex;
  gap: var(--spacing-050);
  align-items: center;

  color: var(--text-primary);
  text-decoration: none;

  &:hover {
    color: var(--text-link);
  }
}

.header {
  all: unset;

  cursor: pointer;

  display: flex;
  grid-template-columns: auto;
  justify-content: space-between;

  margin-bottom: var(--spacing-100);
}

.title {
  margin: 0;
  font: var(--desktop-text-lg-semibold);
}

.button {
  height: fit-content;
}

.button-count {
  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
}

.icon {
  margin-right: var(--spacing-050);
  color: var(--icon-tertiary);
}

.card-game {
  width: 220px;
}

.content {
  min-height: 202px;
}

.size-m {
  .title {
    font: var(--desktop-text-2xl-semibold);
  }

  .icon {
    width: 32px;
    height: 32px;
    opacity: 0.48;
  }
}

.card-empty {
  width: 220px;
  height: 146px;
  margin-top: var(--spacing-050);

  background-color: var(--background-primary);
  border-radius: var(--border-radius-100);
}
</style>
