<template>
  <GamesSlider
    :title="categoryData.title"
    :icon="categoryData.icon"
    :games="categoryData.games"
    :games-total-count="gamesTotal"
    :link="categoryData.link"
    :size="size"
    :show-player-count="showPlayerCount"
  />
</template>

<script lang="ts" setup>
import GamesSlider from '../GamesSlider/GamesSlider.vue'
import { useCategoryGamesSlider } from './useCategoryGamesSlider'

const props = withDefaults(
  defineProps<{
    category: string
    size?: 's' | 'm'
    showPlayerCount?: boolean
  }>(),
  {
    size: 's',
    showPlayerCount: true,
  },
)
const { category } = toRefs(props)
const { categoryData, gamesTotal } = useCategoryGamesSlider(category)
</script>
